/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useRecoilState } from "recoil";
import InputLine from "../../components/input/inputLine";
import { Blank, CheckBox, Div, EvButton, Font, Form, Input, PageLayout, Row } from "../../styles/commonStyle";
import { cardTitleColor, labelBgColor, modalGreyBtnColor, orangeBtnGradient, primaryColor } from "../../styles/theme";
import { InfoBox } from "./style";
import { carCompanySelector, carSelector, showUserSelector, userPointSelector } from "../../recoil/variableAtom";
import { useEffect, useLayoutEffect, useState } from "react";
import { RESULT, ResponseModel } from "../../model/response";
import { Link, useNavigate } from "react-router-dom";
import { User } from "../../model/user";
import { FieldValues, UseFormRegister, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { updateCompanyUserValidation, updateMemberUserValidation } from "../../validation";
import { APILIST, request } from "../../repository/api";
import { Option } from "react-dropdown";
import { TodayFormatYYYYMMDDHHmmssSSS, checkKeyDown, ediDateNoFormat, getUserIdFromToken, logoutHandler, makeEncryptData, makeHyphenInput, makeMoid, paycardHypen, replaceCardSttus, todayNoFoarmat } from "../../util";
import useFetch from "../../hooks/useFetch";
import EvModal from "../../components/modal";
import _ from "lodash";
import { Car } from "../../model/car";
import Loading from "../../components/loading/loading";
import { errorState, loadingState, loginState, modalState, modalTypeState } from "../../recoil/atoms";
import TopButton from "../../components/topButton/topButton";
import { InfoText, Status } from "../register/components/infoText";
import { isMobile } from "react-device-detect";

const cardStatusKeys = {
  NONE: "알수없음",
  READY: "정상",
  CHARGING: "충전진행중",
  STOP: "정지",
  LOSS: "분실",
  BROKEN: "파손",
  WITHDRAWAL: "탈퇴",
  EXPIRE: "만료",
} as const;
export type cardStatusType = (typeof cardStatusKeys)[keyof typeof cardStatusKeys];

function UserInfoPage() {
  const navigate = useNavigate();
  const [getCheckList, setCheckList] = useState<boolean[]>([false, false]);
  const [getCarCompanyList, setCarCompanyList] = useState<any>();
  const [getCars, setCars] = useState<Option[]>();
  const [validataion, setValidation] = useState(updateMemberUserValidation);
  const [errMsg, setErrMsg] = useRecoilState(errorState);
  const [isLoading, setLoading] = useRecoilState(loadingState);
  const [isModalOpen, setModalOpen] = useRecoilState(modalState);
  const [islogin, setlogin] = useRecoilState(loginState);
  const userId = getUserIdFromToken();
  const [carCompanyNm, setCarCompanyNm] = useState<any>();
  const [modalType, setModalType] = useRecoilState(modalTypeState);
  const [isGetCarInfo, setGetCarInfo] = useState<boolean>(false);


  const [Moid, setMoid] = useState("");
  const [EdiDate, setEdiDate] = useState("");
  const [EncryptData, setEncryptData] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(validataion),
    mode: "onSubmit",
    reValidateMode: "onBlur",
  });

  //token을 넣은 이유 : 로그아웃하고 사용자정보를 들어가면 이전정보가 계속 나옴. 다른 정보라는것을 알게 하기 위해 토큰을 사용함.
  let accessToken = window.sessionStorage.getItem("accessToken");
  const response: ResponseModel<User> = useFetch({
    recoilSelector: showUserSelector({ token: accessToken! }),
    query: userId,
  });
  const getCarCompanysApi: ResponseModel<any> = useFetch({
    recoilSelector: carCompanySelector,
  });
  const getCarsApi: ResponseModel<Car[]> = useFetch({
    recoilSelector: carSelector,
    query: carCompanyNm,
    setQuery: setCarCompanyNm,
  });

  //get user info
  useLayoutEffect(() => {
    if (response?.result === RESULT.OK) {
      if (response?.data!.ty !== "MEMBER") {
        setValidation(updateCompanyUserValidation);
      } else {
      }
      setValue('phone', response?.data?.phone);
      setValue('email', response?.data?.email);
      setValue('adres', response?.data?.adres);
      setValue('rdnmadr', response?.data?.rdnmadr);
      setValue('adresDetail', response?.data?.adresDetail);
      setCheckList([response.data?.smsRcptnAgreYn === "Y" ? true : false, response.data?.emlRcptnAgreYn === "Y" ? true : false]);
    }
    if (response?.result === RESULT.FAIL) {
      setErrMsg(response?.msg!);
      setModalOpen(true);
      logoutHandler(setlogin, navigate);
    }
  }, [response]);

  //get car company info
  useLayoutEffect(() => {
    if (response === undefined) return;
    if (getCarCompanysApi?.result === RESULT.OK) {
      let tempCompanyList: string[] = ["선택없음", ...(getCarCompanysApi.data ?? null)];
      setCarCompanyList(tempCompanyList);
      setCarCompanyNm(response?.data?.carData?.carModelNm);

      setValue("carModelCompany", response?.data?.carData?.carModelNm, { shouldValidate: true });
    }
    if (getCarCompanysApi?.result === RESULT.FAIL) {
      setErrMsg(getCarCompanysApi.msg!);
      setModalOpen(true);
    }
  }, [getCarCompanysApi, response]);

  //get car info
  useLayoutEffect(() => {
    if (getCarsApi === undefined) {
      return;
    }
    if (getCarsApi?.result === RESULT.OK) {
      let tempCarList: Option[] = [
        {
          label: "선택없음",
          value: "None",
        },
        ...getCarsApi.data!.map((value: Car) => {
          return { label: value.model_nm, value: value.id.toString() };
        }),
      ];
      setCars(tempCarList);
      if (isGetCarInfo === false) {
        for (let i = 0; i < tempCarList.length; i++) {
          if (tempCarList[i].value === String(response?.data?.carData?.carModelId)) {
            setValue("carModelId", tempCarList[i], { shouldValidate: false });
            setGetCarInfo(true);
            break;
          }
        }
      }
    }
    if (getCarsApi?.result === RESULT.FAIL) {
      setErrMsg(getCarsApi.msg!);
      setModalOpen(true);
    }
  }, [getCarsApi]);

  const getCarModelCompanyHandler = (e: any) => {
    setValue("carModelCompany", e.value, { shouldValidate: true });
    setCarCompanyNm(getValues("carModelCompany"));
    setValue(
      "carModelId",
      {
        label: "선택없음",
        value: "None",
      },
      { shouldValidate: false }
    );
  };
  const getCarsHandler = (e: Option) => {
    setValue("carModelId", e, { shouldValidate: true });
  };

  useEffect(() => {
    if (!isLoading) {
      return;
    } else {
      const moid = makeMoid();
      const ediDate = ediDateNoFormat();

      setMoid(moid);
      setEdiDate(ediDate);
      setEncryptData(makeEncryptData(ediDate, moid));
    }
  }, [isLoading]);

  useEffect(() => {
    setValue("smsRcptnAgreYn", getCheckList[0]);
    setValue("emlRcptnAgreYn", getCheckList[1]);
  }, [getCheckList]);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = isMobile
      ? `${process.env.REACT_APP_SMARTRO_MOBILE}${todayNoFoarmat()}`
      : `${process.env.REACT_APP_SMARTRO_WEB}${todayNoFoarmat()}`;
    script.async = false;
    script.addEventListener('load', () => setLoading(true));
    document.body.appendChild(script);
  }, []);

  const requstPay = () => {
    // @ts-ignore
    window.smartropay.init({
      // mode: "STG",
      mode: "REAL",
      actionUri: '/ssb/interface.do'
    });

    // @ts-ignore
    window.smartropay.payment({
      FormId: 'paycardForm'
    });
  }

  const onClickChangePaycardHandler = () => {
    // 결제카드 변경
    requstPay();
  }


  const onSubmit = async (data: any) => {
    let formData = new FormData();
    let body = {};
    let result: ResponseModel<any>;
    if (!window.confirm("저장하시겠습니까?")) {
      return;
    }
    if (response!.data!.ty === "COMPANY") {
      formData.append("id", response.data!.id);
      formData.append("ty", response.data!.ty);
      formData.append("phone", data.phone);
      formData.append("email", data.email);
      formData.append("adres", data.adres);
      formData.append("rdnmadr", data.rdnmadr);
      formData.append("adresDetail", data.adresDetail);
      formData.append("zipCode", data.zipCode ?? null);
      formData.append("userCarId", response.data!.carData.id);
      formData.append("carModelId", data.carModelId.value);
      formData.append("carNo", data.carNo);
      formData.append("managerNm", data.managerNm);
      formData.append("managerCttpc", data.managerCttpc);
      formData.append("managerEmail", data.managerEmail);
      formData.append("coFax", data.coFax);
      formData.append("smsRcptnAgreYn", data.smsRcptnAgreYn ? "Y" : "N");
      formData.append("emlRcptnAgreYn", data.emlRcptnAgreYn ? "Y" : "N");
      if (data.file[0]) {
        formData.append("file", data.file[0]);
      }
      if (response.data!.fileId) {
        formData.append("fileId", response.data!.fileId);
      }
      result = await request(APILIST.UPDATEUSER, "filePut", undefined, undefined, formData);
    } else if (response!.data!.ty === "MEMBER") {
      formData.append("id", response.data!.id);
      formData.append("ty", response.data!.ty);
      formData.append("phone", data.phone);
      formData.append("email", data.email);
      formData.append("adres", data.adres);
      formData.append("rdnmadr", data.rdnmadr);
      formData.append("adresDetail", data.adresDetail);
      formData.append("zipCode", data.zipCode ?? null);
      formData.append("smsRcptnAgreYn", data.smsRcptnAgreYn ? "Y" : "N");
      formData.append("emlRcptnAgreYn", data.emlRcptnAgreYn ? "Y" : "N");
      formData.append("userCarId", response!.data!.carData?.id.toString());
      formData.append("carModelId", data.carModelId.value.toString());
      formData.append("carNo", data.carNo);
      result = await request(APILIST.UPDATEUSER, "filePut", undefined, undefined, formData);
    } else {
      setModalOpen(true);
      navigate("/");
    }
    if (result!.result === RESULT.FAIL) {
      setErrMsg(result!.msg!);
      setModalType("inputError");
      setModalOpen(true);
    }
    if (result!.result === RESULT.OK) {
      alert("정상적으로 완료되었습니다.");
      window.location.reload();
    }
  };

  if (!isLoading && response?.result === RESULT.FAIL) {
    return (
      <>
        <PageLayout>
          <Div className="pt-[10rem]">사용자 정보가 존재하지 않습니다.</Div>
        </PageLayout>
        <TopButton />
      </>
    );
  }
  if (response?.result === RESULT.OK) {
    return (
      <>
        <Form onSubmit={handleSubmit(onSubmit)} className="w-full" onKeyDown={checkKeyDown}>
          <PageLayout>
            <Div className="w-full">
              <Font fontSize="1.75rem" fontWeight="500" color={cardTitleColor} changeFontSize="20px" className="w-full flex justify-center">
                마이페이지
              </Font>
              <Blank height="2rem" />
              <Font fontWeight="600" className="w-full p-[7px]">
                회원정보
              </Font>
              <InfoBox>
                <InputLine label="이름" name="nm" type="read" necessary={false} readData={response?.data?.nm} labelColor={labelBgColor} />
                <InputLine label="아이디" name="lgnId" type="read" necessary={false} readData={response?.data?.lgnId} labelColor={labelBgColor} />
                <InputLine label="비밀번호" name="password" type="read" necessary={false} readData={<PasswordChangeBtn />} labelColor={labelBgColor} />
                <InputLine
                  label="연락처"
                  name="phone"
                  type="text"
                  necessary={false}
                  placeholder="숫자만 입력해주세요."
                  register={register}
                  errors={errors.phone ? errors.phone.message : undefined}
                  labelColor={labelBgColor}
                  setValue={setValue}
                  defaultValue={response?.data?.phone}
                  tabIndex={1}
                />
                <InputLine
                  label="이메일"
                  name="email"
                  type="text"
                  necessary={false}
                  placeholder="이메일을 입력해주세요."
                  register={register}
                  errors={errors.email ? errors.email.message : undefined}
                  labelColor={labelBgColor}
                  setValue={setValue}
                  defaultValue={response?.data?.email}
                  tabIndex={2}
                />
                <InputLine
                  label="배송지 주소"
                  name="rdnmadr"
                  type="adres"
                  necessary={false}
                  placeholder="주소를 선택해주세요."
                  register={register}
                  errors={errors.rdnmadr ? errors.rdnmadr.message : undefined}
                  labelColor={labelBgColor}
                  setValue={setValue}
                  defaultValue={response?.data?.rdnmadr}
                  tabIndex={3}
                />
                <Input type="hidden" {...register!("zipCode")} name="zipCode" defaultValue={response?.data?.zipCode} />
                <Input type="hidden" {...register!("adres")} name="adres" defaultValue={response?.data?.adres} />
                <InputLine
                  label="배송지 상세주소"
                  name="adresDetail"
                  type="text"
                  necessary={false}
                  register={register}
                  labelColor={labelBgColor}
                  setValue={setValue}
                  defaultValue={response?.data?.adresDetail}
                  tabIndex={4}
                />
                <InputLine
                  label="결제카드 정보"
                  name="paycard"
                  type="read"
                  necessary={false}
                  readData={
                    <div style={{ 'display': 'flex', 'flexDirection': 'column' }}>
                      <PayCard paycardCo={response?.data?.paycardData?.paycardCo} paycardNo={response?.data?.paycardData?.paycardNo} onClick={onClickChangePaycardHandler} />
                      <InfoText status={Status.INFO} text="멤버십카드로 충전 결제시, 이 카드로 결제됩니다." label={false} />
                    </div>
                  }
                  labelColor={labelBgColor}
                  tabIndex={5}
                />
                <Form id="paycardForm" name="paycardForm" method="post">
                  <input type="hidden" name="PayMethod" value="CARD" placeholder="" />
                  <input type="hidden" name="Mid" maxLength={10} value={process.env.REACT_APP_SMARTRO_MID} placeholder="" />
                  <input type="hidden" name="Moid" maxLength={40} value={Moid} placeholder="특수문자 포함 불가" />
                  <input type="hidden" name="MallUserId" maxLength={20} value={TodayFormatYYYYMMDDHHmmssSSS()} placeholder="" />
                  <input type="hidden" name="MallIp" maxLength={20} value="10.0.0.1" placeholder="" />
                  <input type="hidden" name="UserIp" maxLength={20} value="10.0.0.1" placeholder="" />
                  <input type="hidden" name="ReturnUrl" size={100} className="input" value={process.env.REACT_APP_API_URL + `/api/v1/users/changePaycard/result?id=${response?.data?.id}`} />
                  <input type="hidden" name="VerifyValue" maxLength={100} className="input" value={EncryptData} placeholder="위/변조방지 HASH 데이터" />
                  <input type="hidden" name="EncodingType" maxLength={14} value="utf-8" placeholder="utf-8 or euc-kr" />
                  <input type="hidden" name="SspMallId" maxLength={20} value={process.env.REACT_APP_SMARTRO_SSPMALLID} placeholder="테스트용 SspMallId" />
                  <input type="hidden" name="EdiDate" maxLength={14} value={EdiDate} placeholder="" />
                  <input type="hidden" name="IsPwdPass" maxLength={1} value="Y" placeholder="" />
                </Form>
                <InputLine
                  label="마케팅·광고성 메시지 수신 동의"
                  name="check"
                  type="read"
                  necessary={false}
                  readData={<CheckboxComponent register={register} getCheckList={getCheckList} setCheckList={setCheckList} />}
                  labelColor={labelBgColor}
                  tabIndex={6}
                />
              </InfoBox>
              {response?.data?.ty === "COMPANY" ? (
                <Div className="w-full">
                  <Blank height="10px"></Blank>
                  <Font fontWeight="600" className="w-full p-[7px]">
                    담당자 및 기업정보
                  </Font>
                  <InfoBox>
                    <InputLine
                      label="담당자명"
                      name="managerNm"
                      type="text"
                      necessary={false}
                      placeholder="담당자 이름을 입력해주세요."
                      register={register}
                      errors={errors.managerNm ? errors.managerNm.message : undefined}
                      labelColor={labelBgColor}
                      setValue={setValue}
                      defaultValue={response?.data?.managerNm}
                      tabIndex={7}
                    />
                    <InputLine
                      label="담당자 이메일"
                      name="managerEmail"
                      type="text"
                      necessary={false}
                      placeholder="담당자 이메일을 입력해주세요."
                      register={register}
                      errors={errors.managerEmail ? errors.managerEmail.message : undefined}
                      labelColor={labelBgColor}
                      setValue={setValue}
                      defaultValue={response?.data?.managerEmail}
                      tabIndex={8}
                    />
                    <InputLine
                      label="담당자 연락처"
                      name="managerCttpc"
                      type="text"
                      necessary={false}
                      placeholder="담당자 연락처를 입력해주세요."
                      register={register}
                      errors={errors.managerCttpc ? errors.managerCttpc.message : undefined}
                      labelColor={labelBgColor}
                      setValue={setValue}
                      defaultValue={response?.data?.managerCttpc}
                      tabIndex={9}
                    />
                    <InputLine
                      label="FAX"
                      name="coFax"
                      type="text"
                      necessary={false}
                      placeholder="숫자만 입력해주세요."
                      register={register}
                      errors={errors.coFax ? errors.coFax.message : undefined}
                      labelColor={labelBgColor}
                      setValue={setValue}
                      defaultValue={response?.data?.coFax}
                      tabIndex={10}
                    />
                    <InputLine
                      label="구비서류"
                      name="file"
                      type="file"
                      necessary={false}
                      register={register}
                      errors={errors.file ? errors.file.message : undefined}
                      noBorder={true}
                      labelColor={labelBgColor}
                      tabIndex={11}
                    ></InputLine>
                  </InfoBox>
                  <Blank height="10px"></Blank>
                </Div>
              ) : null}
              <Font fontWeight="600" className="w-full p-[7px]">
                차량정보
              </Font>
              <InfoBox>
                <InputLine
                  label="차량제조사"
                  name="carModelCompany"
                  type="dropdown"
                  necessary={true}
                  labelColor={labelBgColor}
                  placeholder=""
                  register={register}
                  errors={errors.carModelCompany ? errors.carModelCompany.message : undefined}
                  options={
                    getCarCompanyList ?? [
                      {
                        label: "선택없음",
                        value: "None",
                      },
                    ]
                  }
                  setValue={setValue}
                  getValues={getValues}
                  onChange={getCarModelCompanyHandler}
                  tabIndex={12}
                ></InputLine>
                <InputLine
                  label="차량정보"
                  name="carModelId"
                  type="dropdown"
                  necessary={true}
                  placeholder=""
                  labelColor={labelBgColor}
                  register={register}
                  errors={errors.carModelId ? errors.carModelId.message : undefined}
                  options={
                    getCars ?? [
                      {
                        label: "선택없음",
                        value: "None",
                      },
                    ]
                  }
                  setValue={setValue}
                  getValues={getValues}
                  onChange={getCarsHandler}
                  tabIndex={13}
                ></InputLine>
                <InputLine
                  label="차량번호"
                  name="carNo"
                  type="text"
                  necessary={true}
                  labelColor={labelBgColor}
                  placeholder="대표 차량번호를 입력해주세요."
                  register={register}
                  errors={errors.carNo ? errors.carNo.message : undefined}
                  noBorder={true}
                  setValue={setValue}
                  defaultValue={response?.data?.carData?.carNo}
                  tabIndex={14}
                ></InputLine>
              </InfoBox>
              <Blank height="10px"></Blank>
              <Font fontWeight="600" className="w-full p-[7px]">
                멤버십카드
              </Font>
              {response?.data?.cardData ? (
                <InfoBox>
                  <InputLine
                    label="카드번호"
                    name="cardNo"
                    type="read"
                    necessary={true}
                    labelColor={labelBgColor}
                    noBorder={false}
                    readData={response?.data?.cardData?.cardNo}
                    tabIndex={15}
                  ></InputLine>
                  <InputLine
                    label="카드상태"
                    name="cardStatus"
                    type="read"
                    necessary={true}
                    labelColor={labelBgColor}
                    noBorder={false}
                    readData={replaceCardSttus(response?.data?.cardData?.sttus!)}
                    tabIndex={16}
                  ></InputLine>
                </InfoBox>
              ) : (
                <InfoBox>
                  <Div className="w-full bg-[#f7f7f7] p-[0.4rem]">
                    <Font fontWeight="500" fontSize="0.9rem">
                      회원가입 후 2영업일 이내에 자동 발급됩니다.
                    </Font>
                  </Div>
                </InfoBox>
              )}
              <Div className="flex w-[100%] justify-center py-[3rem]">
                <EvButton color="#FFF" bgColor={primaryColor} width="7rem" type="submit">
                  수정
                </EvButton>
                <Blank width="1rem"></Blank>
                <Link to="/close">
                  <EvButton bgColor={modalGreyBtnColor} color="#fff" width="7rem" type="button">
                    회원탈퇴
                  </EvButton>
                </Link>
              </Div>
              <EvModal type={modalType} />
            </Div>
          </PageLayout>
        </Form>
        <TopButton />
      </>
    );
  } else {
    return (
      <>
        <PageLayout>
          <Div className="w-full">
            <Font fontSize="1.75rem" fontWeight="500" color={cardTitleColor} changeFontSize="20px" className="w-full justify-center">
              마이페이지
            </Font>
            <Blank height="2rem" />
            <Font fontWeight="600" className="w-full p-[7px]">
              회원정보
            </Font>
            <InfoBox>
              <InputLine label="이름" name="nm" type="read" necessary={false} readData={response?.data?.nm} labelColor={labelBgColor} />
              <InputLine label="아이디" name="lgnId" type="read" necessary={false} readData={response?.data?.lgnId} labelColor={labelBgColor} />
              <InputLine label="비밀번호" name="password" type="read" necessary={false} readData={<PasswordChangeBtn />} labelColor={labelBgColor} />
              <InputLine
                label="연락처"
                name="phone"
                type="text"
                necessary={false}
                placeholder="숫자만 입력해주세요."
                register={register}
                errors={errors.phone ? errors.phone.message : undefined}
                labelColor={labelBgColor}
                setValue={setValue}
                defaultValue={response?.data?.phone}
                tabIndex={1}
              />
              <InputLine
                label="이메일"
                name="email"
                type="text"
                necessary={false}
                placeholder="이메일을 입력해주세요."
                register={register}
                errors={errors.email ? errors.email.message : undefined}
                labelColor={labelBgColor}
                setValue={setValue}
                defaultValue={response?.data?.email}
                tabIndex={2}
              />
              <InputLine
                label="배송지 주소"
                name="rdnmadr"
                type="adres"
                necessary={false}
                placeholder="주소를 선택해주세요."
                register={register}
                errors={errors.rdnmadr ? errors.rdnmadr.message : undefined}
                labelColor={labelBgColor}
                setValue={setValue}
                defaultValue={response?.data?.rdnmadr}
                tabIndex={3}
              />
              <Input type="hidden" {...register!("zipCode")} name="zipCode" defaultValue={response?.data?.zipCode} />
              <Input type="hidden" {...register!("adres")} name="adres" defaultValue={response?.data?.adres} />
              <InputLine
                label="배송지 상세주소"
                name="adresDetail"
                type="text"
                necessary={false}
                register={register}
                labelColor={labelBgColor}
                setValue={setValue}
                defaultValue={response?.data?.adresDetail}
                tabIndex={4}
              />
              <InputLine
                label="결제카드 정보"
                name="paycard"
                type="read"
                necessary={false}
                readData={
                  <div style={{ 'display': 'flex', 'flexDirection': 'column' }}>
                    <PayCard paycardCo={response?.data?.paycardData?.paycardCo} paycardNo={response?.data?.paycardData?.paycardNo} onClick={onClickChangePaycardHandler} />
                    <InfoText status={Status.INFO} text="멤버십카드로 충전 결제시, 이 카드로 결제됩니다." label={false} />
                  </div>
                }
                labelColor={labelBgColor}
                tabIndex={5}
              />
              <Form id="paycardForm" name="paycardForm" method="post">
                <input type="hidden" name="PayMethod" value="CARD" placeholder="" />
                <input type="hidden" name="Mid" maxLength={10} value={process.env.REACT_APP_SMARTRO_MID} placeholder="" />
                <input type="hidden" name="Moid" maxLength={40} value={Moid} placeholder="특수문자 포함 불가" />
                <input type="hidden" name="MallUserId" maxLength={20} value={TodayFormatYYYYMMDDHHmmssSSS()} placeholder="" />
                <input type="hidden" name="MallIp" maxLength={20} value="10.0.0.1" placeholder="" />
                <input type="hidden" name="UserIp" maxLength={20} value="10.0.0.1" placeholder="" />
                <input type="hidden" name="ReturnUrl" size={100} className="input" value={process.env.REACT_APP_API_URL + `/api/v1/users/changePaycard/result?id=${response?.data?.id}`} />
                <input type="hidden" name="VerifyValue" maxLength={100} className="input" value={EncryptData} placeholder="위/변조방지 HASH 데이터" />
                <input type="hidden" name="EncodingType" maxLength={14} value="utf-8" placeholder="utf-8 or euc-kr" />
                <input type="hidden" name="SspMallId" maxLength={20} value={process.env.REACT_APP_SMARTRO_SSPMALLID} placeholder="테스트용 SspMallId" />
                <input type="hidden" name="EdiDate" maxLength={14} value={EdiDate} placeholder="" />
                <input type="hidden" name="IsPwdPass" maxLength={1} value="Y" placeholder="" />
              </Form>
              <InputLine
                label="마케팅·광고성 메시지 수신 동의"
                name="check"
                type="read"
                necessary={false}
                readData={<CheckboxComponent register={register} getCheckList={getCheckList} setCheckList={setCheckList} />}
                labelColor={labelBgColor}
                tabIndex={6}
              />
              <Loading isLoading={true} cssStyle="w-full min-h-[500px] flex bg-[#FFF]/60 items-center" />
            </InfoBox>
          </Div>
        </PageLayout>
        <TopButton />
      </>
    );
  }
}

function PayCard({ paycardCo, paycardNo, onClick }: { paycardCo: string | undefined; paycardNo: string | undefined, onClick: any }) {
  return (
    <Font fontWeight="500">
      현재등록카드:&nbsp;{paycardCo}&nbsp;
      {paycardHypen(paycardNo)}
      <br />
      <Blank width="0.5rem" />
      <EvButton bgColor={modalGreyBtnColor} color="#fff" fontSize="1rem" onClick={onClick} type="button">
        결제카드 변경
      </EvButton>

    </Font>
  );
}
function PasswordChangeBtn() {
  const navigate = useNavigate();
  return (
    <EvButton bgColor={modalGreyBtnColor} color="#fff" fontSize="1rem" onClick={() => navigate("/password")} type="button">
      비밀번호 변경
    </EvButton>
  );
}

function CheckboxComponent({
  register,
  getCheckList,
  setCheckList,
}: {
  register: UseFormRegister<FieldValues>;
  getCheckList: boolean[];
  setCheckList: React.Dispatch<React.SetStateAction<boolean[]>>;
}) {
  return (
    <Row className="w-full p-[0.5rem]">
      <label className="w-[50%]">
        <Font className="w-full flex items-center" fontSize="calc(0.7rem + 0.3vw)">
          <CheckBox
            type="checkbox"
            {...register("smsRcptnAgreYn")}
            name="smsRcptnAgreYn"
            className="me-[8px]"
            checked={getCheckList[0]}
            onChange={() => setCheckList([!getCheckList[0], getCheckList[1]])}
          />
          SMS
        </Font>
      </label>
      <label className="w-full">
        <Font className="w-[50%] flex items-center" fontSize="calc(0.7rem + 0.3vw)">
          <CheckBox
            type="checkbox"
            {...register("emlRcptnAgreYn")}
            name="emlRcptnAgreYn"
            className="me-[8px]"
            checked={getCheckList[1]}
            onChange={() => setCheckList([getCheckList[0], !getCheckList[1]])}
          />
          이메일
        </Font>
      </label>
    </Row>
  );
}

export default UserInfoPage;
